import React from 'react'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'

// Components
import Image from 'components/shared/ImageGatsby'
import ButtonDefault from 'components/elements/ButtonDefault'
import Content from 'components/shared/Content'

const StyledButtonDefault = styled(ButtonDefault)`
  & > a {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.color.text.main};
    color: ${(props) => props.theme.color.text.main};
    padding: 17px 15px;

    &:hover {
      color: ${(props) => props.theme.color.text.main};
    }
  }
`

const StyledImage = styled(Image)`
  max-height: 300px;
`

interface ProductsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Products
  location?: any
}

const Products: React.FC<ProductsProps> = ({ fields }) => (
  <section className="my-5">
    <div className="container">
      <div className="row">
        {fields?.product?.map((edge, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-lg-4 col-md-6 mb-5" key={index}>
            <StyledImage image={edge?.image} alt="Lofthus" className="mb-4" loading="lazy" />
            <div>
              <Content content={edge?.description} />
              <StyledButtonDefault
                to={edge?.link?.url || ''}
                className="my-4 text-center"
              >
                {edge?.link?.title}
              </StyledButtonDefault>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Products
